import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'btwin-smartsize';

  constructor(
    private router: Router
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let pageView: string;
        switch(event.urlAfterRedirects) {
          case "/home":
            pageView = "Profil"
            break;
          case "/morphologie":
            pageView = "Morphologie"
            break;
          case "/pratique":
            pageView = "Pratique"
            break;
          case "/resultat":
            pageView = "Résultat"
            break;
          case "/resume":
            pageView = "Récapitulatif"
            break;
        }

        gtag('event', 'page_view', {
            page_path: pageView
        })
      })
  }
}