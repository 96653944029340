import { Component } from '@angular/core';
import {InputService} from "../services/input/input.service";
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {GenderType} from "../services/size/size-type.model";

declare const gtag: Function;

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent {
  constructor(
      private router: Router,
      private inputService: InputService
  ) { }

  errorVisible = false

  form = new FormGroup({
    gender: new FormControl(),
  });

  onWomanClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_une_femme" 
    })
  }

  onManClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_un_homme" 
    })
  }

  onNextClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_continuer_1" 
    })

    let data = this.form.get("gender").value
    let genderType: GenderType;
    switch(data) {
      case "men": genderType = GenderType.male; break
      case "women": genderType = GenderType.female; break
      default:
        this.errorVisible = true
        throw new Error("Invalid Gender")
    }

    this.inputService.setGender(genderType)
    this.router.navigate(['/morphologie'])
  }
}
