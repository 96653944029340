import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProfilComponent} from "./profil/profil.component";
import {MorphologieComponent} from "./morphologie/morphologie.component";
import {PratiqueComponent} from "./pratique/pratique.component";
import {ResultatComponent} from "./resultat/resultat.component";
import {ResumeComponent} from "./resume/resume.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {path: '', redirectTo: '/home', pathMatch: 'full'},
      {path: 'home', component: ProfilComponent},
      {path: 'morphologie', component: MorphologieComponent},
      {path: 'pratique', component: PratiqueComponent},
      {path: 'resultat', component: ResultatComponent},
      {path: 'resume', component: ResumeComponent},
      {path: '**', redirectTo: '/home', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
