import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ProfilComponent } from './profil/profil.component';
import { MorphologieComponent } from './morphologie/morphologie.component';
import { PratiqueComponent } from './pratique/pratique.component';
import { ResultatComponent } from './resultat/resultat.component';
import { ResumeComponent } from './resume/resume.component';
import {AppComponent} from "./app.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
      AppComponent,
      ProfilComponent,
      MorphologieComponent,
      PratiqueComponent,
      ResultatComponent,
      ResumeComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
