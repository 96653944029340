import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {InputService} from "../services/input/input.service";
import {SizeService} from "../services/size/size.service";

declare const gtag: Function;

enum ResultatType { ReculDeSelle, HauteurDeSelle, SelleGuidon, HauteurSelleCadre }
interface ResultatModal {
  title: string,
  materiel: string,
  description: string,
  image: string
}

@Component({
  selector: 'app-resultat',
  templateUrl: './resultat.component.html',
  styleUrls: ['./resultat.component.scss']
})
export class ResultatComponent implements OnInit {

  constructor(
    private router: Router,
    private inputService: InputService,
    private sizeService: SizeService
  ) { }

  resultatType = ResultatType;
  selectedModal: ResultatModal[] = null

  reculDeSelle = this.sizeService.computeRecoilSaddle(this.inputService.type, this.inputService.data)
  hauteurDeSelle = this.sizeService.computeHeightSaddle(this.inputService.type, this.inputService.data)
  selleGuidon = this.sizeService.computeDistanceFrameSaddle(this.inputService.type, this.inputService.data)
  hauteurSelleCadre = this.sizeService.computeHeightFrameSaddle(this.inputService.type, this.inputService.data)

  ngOnInit(): void {
  }

  openModal(type: ResultatType) {
    const formSection = document.getElementById("section--form");
    if (formSection) {
      formSection.style.display = "none";
    }
    switch(type) {
      case ResultatType.ReculDeSelle:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_en_savoir_plus_recul" 
        })
        this.selectedModal = [
            {
              description: "Il sera nécessaire de prendre le repère de la selle c’est-à-dire son centre qui mesure 8 cm.",
              image: "assets/images/tuto-common.jpg",
              materiel: "un mètre-ruban",
              title: "Centre de la selle"
            },
            {
              description: "Mesurer la distance entre le centre de la selle soit le repère de 8 cm et le centre du cintre. <br />" +
                  "Vous pouvez également consulter notre playlist <b><a href=\"https://www.youtube.com/playlist?list=PLVYtuDK6gYA_m85WFgxMqylJSasOOUqM6\">tuto selle vélo</a></b> ainsi que notre article <b><a href=\"https://support.decathlon.fr/les-3-positions-de-selle-velo-a-connaitre-pour-plus-de-confort\">comment bien régler sa selle vélo</a></b>",
              image: "assets/images/tuto-recul-selle.jpg",
              materiel: "un mètre-ruban, un fil à plomb",
              title: "Recul de selle"
            }
        ]
        break
      case ResultatType.HauteurDeSelle:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_en_savoir_plus_hauteur" 
        })
        this.selectedModal = [
          {
            description: "Il sera nécessaire de prendre le repère de la selle c’est-à-dire son centre qui mesure 8 cm.",
            image: "assets/images/tuto-common.jpg",
            materiel: "un mètre-ruban",
            title: "Centre de la selle"
          },
          {
          description: "Mesurer la distance entre le centre de la selle soit le repère de 8 cm et le centre du cintre. <br />" +
                  "Vous pouvez également consulter notre playlist <b><a href=\"https://www.youtube.com/playlist?list=PLVYtuDK6gYA_m85WFgxMqylJSasOOUqM6\">tuto selle vélo</a></b> ainsi que notre article <b><a href=\"https://support.decathlon.fr/les-3-positions-de-selle-velo-a-connaitre-pour-plus-de-confort\">comment bien régler sa selle vélo</a></b>",
              image: "assets/images/tuto-hauteur-selle.gif",
            materiel: "un mètre-ruban",
            title: "Hauteur de selle"
          }
        ]
        break
      case ResultatType.SelleGuidon:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_en_savoir_plus_selle_guidon" 
        })
        this.selectedModal = [
          {
            description: "Il sera nécessaire de prendre le repère de la selle c’est-à-dire son centre qui mesure 8 cm.",
            image: "assets/images/tuto-common.jpg",
            materiel: "un mètre-ruban",
            title: "Centre de la selle"
          },
          {
            description: "Mesurer la distance entre le centre de la selle soit le repère de 8 cm et le centre du cintre. <br />" +
                  "Vous pouvez également consulter notre playlist <b><a href=\"https://www.youtube.com/playlist?list=PLVYtuDK6gYA_m85WFgxMqylJSasOOUqM6\">tuto selle vélo</a></b> ainsi que notre article <b><a href=\"https://support.decathlon.fr/les-3-positions-de-selle-velo-a-connaitre-pour-plus-de-confort\">comment bien régler sa selle vélo</a></b>",
           image: "assets/images/tuto-selle-guidon.gif",
            materiel: "un mètre-ruban",
            title: "Selle - Guidon"
          }
        ]
        break
      case ResultatType.HauteurSelleCadre:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_en_savoir_plus_drop" 
        })
        this.selectedModal = [
          {
            description: "D = B - A <br /><br />" +
            "D est la différence entre la hauteur de la selle (B) et la hauteur du cintre (A). " +
            "La mesure correspond à la hauteur maximale de la selle.<br /><br />" +
            "Il est possible que vous deviez adapter la hauteur de votre cintre pour que le drop corresponde parfaitement à votre morphologie.",
            image: "assets/images/tuto-drop.jpg",
            materiel: "un mètre-ruban",
            title: "Mesure"
          }
        ]
        break
    }
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo({ top: -20, behavior: 'smooth' });
  }  

  closeModal() {
    const formSection = document.getElementById("section--form");
    if (formSection) {
      formSection.style.display = "block";
    }
    this.selectedModal = null
  }

  onCTAClick() {
    gtag('event','click', { 
      'event_category' : "Click",  
      'event_label' : "click_CTA" 
    })
  }

  onBackClick() {
    gtag('event','click', { 
      'event_category' : "Click",  
      'event_label' : "click_Back" 
    })
    this.router.navigate(['/resume'])
  }
}
