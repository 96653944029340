<main>
  <!-- Left part : Forms and inputs -->
  <section id="section--form">
    <!-- Navigation menu -->
    <div id="navigation">
      <img src="assets/images/logo-decalthlon.svg" alt="Logo Decathlon" id="logo--navigation__width">
      <div id="navigation--content">
        <!-- 1 -->
        <div class="navigation--box line">
          <p class="navigation--box__circle">1</p>
          <p class="navigation--box__text">Profil</p>
        </div>
        <!-- 2 -->
        <div class="navigation--box line">
          <p class="navigation--box__circle">2</p>
          <p class="navigation--box__text">Morpho<span class="display-none">logie</span></p>
        </div>
        <!-- 3 -->
        <div class="navigation--box line">
          <p class="navigation--box__circle">3</p>
          <p class="navigation--box__text">Pratique</p>
        </div>
        <!-- 4 -->
        <div class="navigation--box line">
          <p class="navigation--box__circle">4</p>
          <p class="navigation--box__text">Récap<span class="display-none">itulatif</span></p>
        </div>
        <!-- 5 -->
        <div class="navigation--box">
          <p class="navigation--box__circle">5</p>
          <p class="navigation--box__text">Résultat</p>
        </div>
      </div>
    </div>
    <div id="form">
      <h1>Profil</h1>
      <h2>Êtes-vous une femme ou un homme ?</h2>
      <p class="form--desc">La configuration personnalisée peut varier en fonction de votre sexe</p>
      <form id="form--select" [formGroup]="form" action="#">
        <input class="vtmn-radio-button" type="radio" value="women" formControlName="gender" id="women" name="gender" (click)="onWomanClick()">
        <label for="women">Une femme</label>
        <input class="vtmn-radio-button" type="radio" value="men" formControlName="gender" id="men" name="gender" (click)="onManClick()">
        <label for="men">Un homme</label>
        <p *ngIf="errorVisible" id="error-input-helper-1"
           class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
          Merci de sélectionner un profil
        </p>
      </form>
      <div id="form--circles__1"></div>
      <div id="form--circles__2"></div>
      <div id="form--circles__3"></div>
      <div id="form--circles__4"></div>
    </div>
    <div id="footer">
      <a class="footer--button__next" (click)="onNextClick()">
        <span class="hide-small">Continuer</span><i class="material-icons">chevron_right</i>
      </a>
    </div>
  </section>
  <!-- Right part : Image (only on large devices) -->
  <section id="section--image"></section>
</main>