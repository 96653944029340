import { Injectable } from '@angular/core';
import {GenderType, SizeParameter, SizeType} from "../size/size-type.model";
import {SizeService} from "../size/size.service";

@Injectable({
  providedIn: 'root'
})
export class InputService {
  private localStorageDataKey = "data"
  private localStorageTypeKey = "type"
  public data: SizeParameter;
  public type: SizeType;

  constructor(private sizeService: SizeService) {
    this.type = JSON.parse(localStorage.getItem(this.localStorageTypeKey))
    this.data = JSON.parse(localStorage.getItem(this.localStorageDataKey))
    if(this.data == null) {
      this.data = {
        gender: null,
        tailleInCm: null,
        entrejambeInCm: null,
        torseInCm: null,
        brasInCm: null
      }
    }
  }

  private saveData() {
    localStorage.setItem(this.localStorageTypeKey, JSON.stringify(this.type))
    localStorage.setItem(this.localStorageDataKey, JSON.stringify(this.data))
  }

  setTaille(input: number) {
    this.sizeService.checkTaille(input)
    this.data.tailleInCm = input
    this.saveData()
  }

  setEntrejambe(taille: number, input: number) {
    this.sizeService.checkEntrejambe(taille, input)
    this.data.entrejambeInCm = input
    this.saveData()
  }

  setTorse(taille: number, input: number) {
    this.sizeService.checkTorse(taille, input)
    this.data.torseInCm = input
    this.saveData()
  }

  setBras(taille: number, input: number) {
    this.sizeService.checkBras(taille, input)
    this.data.brasInCm = input
    this.saveData()
  }

  setGender(input: GenderType) {
    this.data.gender = input
    this.saveData()
  }

  setType(input: SizeType) {
    this.type = input
    this.saveData()
  }
}
