import {Injectable} from '@angular/core';
import {SizeParameter, SizeType} from "./size-type.model";

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  constructor() { }

  private computeSiX(type: SizeType, input: SizeParameter): number {
    let entrejambe = input.entrejambeInCm * 10
    if(type == SizeType.loisir) {
      return (0.2931 * entrejambe) - 40
    } else {
      return (0.294 * entrejambe) - 39.68
    }
  }

  private computeSiY(type: SizeType, input: SizeParameter): number {
    let entrejambe = input.entrejambeInCm * 10
    if(type == SizeType.loisir) {
      return (0.9943 * entrejambe) - 138.5
    } else if(type == SizeType.roadConfort) {
      return (0.9869 * entrejambe) - 119
    } else {
      return (0.9869 * entrejambe) - 114
    }
  }

  private computeGiX(type: SizeType, input: SizeParameter): number {
    let entrejambe = input.entrejambeInCm * 10
    let torse = input.torseInCm * 10
    let bras = input.brasInCm * 10
    if(type == SizeType.loisir) {
      return 390.7 + (0.5171 * torse) - (0.8083 * entrejambe) + (0.55 * (bras - 652))
    } else if(type == SizeType.roadConfort) {
      return 417.2 + (0.5114 * torse) - (0.8042 * entrejambe) + (0.542 * (bras - 652))
    } else {
      return 429.2 + (0.5307 * torse) - (0.8295 * entrejambe) + (0.561 * (bras - 652))
    }
  }

  private computeGiY(type: SizeType, input: SizeParameter): number {
    let entrejambe = input.entrejambeInCm * 10

    if(type == SizeType.loisir) {
      if(entrejambe < 720) {
        return 560
      } else {
        return 0.9588 * entrejambe - 105.3
      }
    } else if(type == SizeType.roadConfort) {
      return 0.9682 * entrejambe - 148.2
    } else {
      return 0.8585 * entrejambe - 82
    }
  }

  computeHeightSaddle(type: SizeType, input: SizeParameter): number {
    let six = this.computeSiX(type, input)
    let siy = this.computeSiY(type, input)
    let result = Math.pow(((six * six) + (siy * siy)),0.5) / 10
    return parseFloat(result.toFixed(2))
  }

  computeRecoilSaddle(type: SizeType, input: SizeParameter): number {
    let result = this.computeSiX(type, input) / 10
    return parseFloat(result.toFixed(2))
  }

  computeDistanceFrameSaddle(type: SizeType, input: SizeParameter): number {
    let six = this.computeSiX(type, input)
    let siy = this.computeSiY(type, input)
    let gix = this.computeGiX(type, input)
    let giy = this.computeGiY(type, input)

    let result = Math.pow(Math.pow(six+gix, 2) + Math.pow(siy-giy,2), 0.5)
    switch (type) {
      case SizeType.roadPerformance:
      case SizeType.loisir:
        return parseFloat((result / 10).toFixed(2))
      case SizeType.roadConfort:
        return parseFloat(((result - 10) / 10).toFixed(2))
    }
  }

  computeHeightFrameSaddle(type: SizeType, input: SizeParameter): number {
    let siy = this.computeSiY(type, input)
    let giy = this.computeGiY(type, input)
    let result = (siy - giy) / 10
    return parseFloat(result.toFixed(2))
  }

  checkTaille(input: number) {
    if(input == null) throw new Error("InvalidTaille -- null")
    if(isNaN(input)) throw Error("NaN number")
    if(input < 150 || input > 210) throw new Error('InvalidTaille -- $input')
  }

  checkEntrejambe(taille: number, input: number) {
    this.checkTaille(taille)
    if(input == null) throw new Error("InvalidEntrejambe -- null")
    if(isNaN(input)) throw Error("NaN number")
    let ref = (taille * 0.5905)-22.266
    //if (Math.abs(ref - input) > 4) throw new Error('WarningEntrejambe $input')
    if (Math.abs(ref - input) > 8) throw new Error('InvalidEntrejambe $input')
  }

  checkTorse(taille: number, input: number) {
    this.checkTaille(taille)
    if(input == null) throw new Error("InvalidTorse -- null")
    if(isNaN(input)) throw Error("NaN number")
    let ref = (taille * 0.8826)-11.881
    //if (Math.abs(ref - input) > 3) throw new Error('WarningTorse $input')
    if (Math.abs(ref - input) > 5) throw new Error('InvalidTorse $input')
  }

  checkBras(taille: number, input: number) {
    this.checkTaille(taille)
    if(input == null) throw new Error("InvalidBras -- null")
    if(isNaN(input)) throw Error("NaN number")
    let ref = (taille * 0.3744)-2.4348
    //if (Math.abs(ref - input) > 4) throw new Error('WarningBras $input')
    if (Math.abs(ref - input) > 8) throw new Error('InvalidBras $input')
  }
}
