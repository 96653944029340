import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {InputService} from "../services/input/input.service";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {SizeType} from "../services/size/size-type.model";
import {tryCatch} from "rxjs/internal-compatibility";

declare const gtag: Function;

enum FormType { Taille, Entrejambe, Torse, Bras }
interface Modal {
  title: string,
  materiel: string,
  description: string,
  image: string
}

@Component({
  selector: 'app-morphologie',
  templateUrl: './morphologie.component.html',
  styleUrls: ['./morphologie.component.scss']
})
export class MorphologieComponent implements OnInit {

  constructor(
      private router: Router,
      private inputService: InputService,
  ) { }

  selectedModal: Modal = null

  formType = FormType;
  form = new FormGroup({
    taille: new FormControl(),
    entrejambe: new FormControl(),
    torse: new FormControl(),
    bras: new FormControl()
  });

  error = {
    taille: null,
    entrejambe: null,
    torse: null,
    bras: null
  };

  ngOnInit(): void {
    let data = this.inputService.data
    this.form.get("taille").setValue(data.tailleInCm)
    this.form.get("entrejambe").setValue(data.entrejambeInCm)
    this.form.get("torse").setValue(data.torseInCm)
    this.form.get("bras").setValue(data.brasInCm)
    if(this.inputService.data.gender == undefined) this.router.navigate(['/home'])
  }

  getClass(type: FormType): string {
    let data: AbstractControl;
    switch(type) {
      case FormType.Entrejambe:
        data = this.form.get("entrejambe")
        if(!data.dirty && (data.value == "" || this.error.entrejambe == null)) return ""
        return this.error.entrejambe ? "vtmn-text-input--error" : "vtmn-text-input--valid"

      case FormType.Taille:
        data = this.form.get("taille")
        if(!data.dirty && (data.value == "" || this.error.taille == null)) return ""
        return this.error.taille ? "vtmn-text-input--error" : "vtmn-text-input--valid"

      case FormType.Bras:
        data = this.form.get("bras")
        if(!data.dirty && (data.value == "" || this.error.bras == null)) return ""
        return this.error.bras ? "vtmn-text-input--error" : "vtmn-text-input--valid"

      case FormType.Torse:
        data = this.form.get("torse")
        if(!data.dirty && (data.value == "" || this.error.torse == null)) return ""
        return this.error.torse ? "vtmn-text-input--error" : "vtmn-text-input--valid"
    }
  }

  openModal(type: FormType) {
    switch(type) {
      case FormType.Entrejambe:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_comment_mesurer_entrejambe" 
        })
        this.selectedModal = {
          description: "Mettez-vous debout, pieds nus et adossé au mur. Vos pieds sont légèrement écartés (environ 15 à 20 cm max) et vos talons touchent le mur.<br />Regardez droit devant vous.<br />" +
              "Ensuite, posez une équerre, ou tout autre objet possédant un angle droit (ex : un livre), contre le mur et faites-le glisser entre vos jambes jusqu’à votre périnée: appliquez une petite force pour imiter la pression de la selle.<br />" +
              "Mesurez la distance entre l’angle droit jusqu'au sol.",
          image: "assets/images/entrejambe.jpg",
          materiel: "un mètre-ruban, une équerre (ou objet avec angle droit) et un crayon.",
          title: "Entrejambe"
        }
        break
      case FormType.Taille:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_comment_mesurer_taille" 
        })
        this.selectedModal = {
          description: "Mettez-vous debout, les pieds joints et dos au mur. Regardez bien droit devant vous. " +
              "Prenez le livre, posez-le sur votre tête et tracez un trait via votre crayon. Vous n’avez plus qu’à mesurer la distance entre le trait et le sol.",
          image: "assets/images/taille.jpg",
          materiel: "un mètre-ruban, un livre et un crayon.",
          title: "Taille"
        }
        break
      case FormType.Bras:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_comment_mesurer_bras" 
        })
        this.selectedModal = {
          description: "Tenez-vous debout et mettez vos bras le long du corps. Pour vous repérer, prenez un crayon dans votre main ce qui vous permettra d’avoir un repère pour la mesure. " +
              "Mesurez la distance à partir de l’épaule jusqu’au crayon.",
          image: "assets/images/bras.jpg",
          materiel: "un mètre-ruban, un crayon.",
          title: "Longueur du bras"
        }
        break
      case FormType.Torse:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_comment_mesurer_torse" 
        })
        this.selectedModal = {
          description: "Tenez-vous bien droit et mettez un crayon au niveau du haut de votre sternum. Vous pouvez tracer le trait sur un mur pour ensuite prendre la mesure à partir de votre repère jusqu’au sol.",
          image: "assets/images/torse.jpg",
          materiel: "un mètre-ruban.",
          title: "Mesure du Torse au Sol"
        }
        break
    }
    this.scrollToTop();
  }
  
  scrollToTop() {
    window.scrollTo({ top: -20, behavior: 'smooth' });
  }  

  closeModal() {
    this.selectedModal = null
  }

  onInputClick(type: FormType) {
    switch(type) {
      case FormType.Entrejambe:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_entrejambe" 
        })
        break
      case FormType.Taille:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_taille" 
        })
        break
      case FormType.Bras:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_bras" 
        })
        break
      case FormType.Torse:
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_torse" 
        })
        break
    }
  }

  onNextClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_continuer_2" 
    })

    let taille = this.form.get("taille").value
    try {
      this.inputService.setTaille(Number(taille))
      this.error.taille = false
    } catch (e) {
      this.error.taille = true
      return
    }

    try {
      this.inputService.setEntrejambe(taille, Number(this.form.get("entrejambe").value))
      this.error.entrejambe = false
    } catch (e) {
      this.error.entrejambe = true
      return
    }

    try {
      this.inputService.setTorse(taille, Number(this.form.get("torse").value))
      this.error.torse = false
    } catch (e) {
      this.error.torse = true
      return
    }

    try {
      this.inputService.setBras(taille, Number(this.form.get("bras").value))
      this.error.bras = false
    } catch (e) {
      this.error.bras = true
      return
    }

    this.router.navigate(['/pratique'])
  }
}
