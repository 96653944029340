<main>
    <!-- Left part : Forms and inputs -->
    <section id="section--form">
        <!-- Navigation menu -->
        <div id="navigation">
            <img src="assets/images/logo-decalthlon.svg" alt="Logo Decathlon" id="logo--navigation__width">
            <div id="navigation--content">
                <!-- 1 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/profil" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">1</span>
                    </a>
                    <p class="navigation--box__text">Profil</p>
                </div>
                <!-- 2 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">2</a>
                    <p class="navigation--box__text">Morpho<span class="display-none">logie</span></p>
                </div>
                <!-- 3 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">3</a>
                    <p class="navigation--box__text">Pratique</p>
                </div>
                <!-- 4 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">4</a>
                    <p class="navigation--box__text">Récap<span class="display-none">itulatif</span></p>
                </div>
                <!-- 5 -->
                <div class="navigation--box">
                    <a href="#" class="navigation--box__circle">5</a>
                    <p class="navigation--box__text">Résultat</p>
                </div>
            </div>
        </div>
        <div id="form" [formGroup]="form">
            <h1>Morphologie</h1>
            <h2>Indiquez vos mesures</h2>
            <p class="form--desc">Pour se mesurer, il est recommandé d’être accompagné d’une autre personne pour être
                plus précis et ne pas
                obtenir de mauvaises mensurations.</p>
            <div class="morphologie--form">
                <div class="morphologie--content">
                    <div class="block morphologie--content__modifier">
                        <label class="vtmn-text-input_label" for="taille-input">Taille</label>
                        <div class="vtmn-text-input_container">
                            <input type="text"
                                   class="vtmn-text-input"
                                   formControlName="taille"
                                   [ngClass]="getClass(formType.Taille)"
                                   id="taille-input"
                                   placeholder="Mensuration"
                                   aria-describedby="taille-input"
                                   (click)="onInputClick(formType.Taille)" />
                        </div>
                        <p id="error-input-helper-1"
                           *ngIf="error.taille"
                           class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
                            Aucun ajustement ne correspond à la mensuration saisie
                        </p>
                    </div>
                    <div class="block">
                        <div class="vtmn-select_container label">
                            <label for="taille-select">Taille</label>
                            <select name="sports" id="taille-select" autocomplete="on">
                                <option value="cm" selected>cm</option>
                            </select>
                        </div>
                    </div>
                    <a (click)="openModal(formType.Taille)">
                        <i class="material-symbols-outlined">info</i>
                        <p>Comment mesurer ?</p>
                    </a>
                </div>
                <div class="morphologie--content">
                    <div class="block morphologie--content__modifier">
                        <label class="vtmn-text-input_label" for="entrejambe-input">Entrejambe</label>
                        <div class="vtmn-text-input_container">
                            <input type="text"
                                   class="vtmn-text-input"
                                   formControlName="entrejambe"
                                   [ngClass]="getClass(formType.Entrejambe)"
                                   id="entrejambe-input"
                                   placeholder="Mensuration"
                                   aria-describedby="entrejambe-input"
                                   (click)="onInputClick(formType.Entrejambe)" />
                        </div>
                        <p id="error-input-helper-2"
                           *ngIf="error.entrejambe"
                           class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
                            Aucun ajustement ne correspond à la mensuration saisie
                        </p>
                    </div>
                    <div class="block">
                        <div class="vtmn-select_container label">
                            <label for="entrejambe-select">Entre</label>
                            <select name="sports" id="entrejambe-select" autocomplete="on">
                                <option value="cm" selected>cm</option>
                            </select>
                        </div>
                    </div>
                    <a (click)="openModal(formType.Entrejambe)">
                        <i class="material-symbols-outlined">info</i>
                        <p>Comment mesurer ?</p>
                    </a>
                </div>
                <div class="morphologie--content">
                    <div class="block morphologie--content__modifier">
                        <label class="vtmn-text-input_label" for="torse-input">Torse</label>
                        <div class="vtmn-text-input_container">
                            <input type="text"
                                   class="vtmn-text-input"
                                   formControlName="torse"
                                   [ngClass]="getClass(formType.Torse)"
                                   id="torse-input"
                                   placeholder="Mensuration"
                                   aria-describedby="torse-input"
                                   (click)="onInputClick(formType.Torse)" />
                        </div>
                        <p id="error-input-helper-3"
                           *ngIf="error.torse"
                           class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
                            Aucun ajustement ne correspond à la mensuration saisie
                        </p>
                    </div>
                    <div class="block">
                        <div class="vtmn-select_container label">
                            <label for="torse-select">Torse</label>
                            <select name="sports" id="torse-select" autocomplete="on">
                                <option value="cm" selected>cm</option>
                            </select>
                        </div>
                    </div>
                    <a (click)="openModal(formType.Torse)">
                        <i class="material-symbols-outlined">info</i>
                        <p>Comment mesurer ?</p>
                    </a>
                </div>
                <div class="morphologie--content">
                    <div class="block morphologie--content__modifier">
                        <label class="vtmn-text-input_label" for="bras-input">Bras</label>
                        <div class="vtmn-text-input_container">
                            <input type="text"
                                   class="vtmn-text-input"
                                   id="bras-input"
                                   formControlName="bras"
                                   placeholder="Mensuration"
                                   [ngClass]="getClass(formType.Bras)"
                                   aria-describedby="bras-input"
                                   (click)="onInputClick(formType.Bras)" />
                        </div>
                        <p id="error-input-helper-4"
                           *ngIf="error.bras"
                           class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
                            Aucun ajustement ne correspond à la mensuration saisie
                        </p>
                    </div>
                    <div class="block">
                        <div class="vtmn-select_container label">
                            <label for="bras-select">Bras</label>
                            <select name="sports" id="bras-select" autocomplete="on">
                                <option value="cm" selected>cm</option>
                            </select>
                        </div>
                    </div>
                    <a (click)="openModal(formType.Bras)">
                        <i class="material-symbols-outlined">info</i>
                        <p>Comment mesurer ?</p>
                    </a>
                </div>
            </div>
            <div id="form--circles__1"></div>
            <div id="form--circles__2"></div>
            <div id="form--circles__3"></div>
            <div id="form--circles__4"></div>
        </div>
        <div id="footer--morphologie">
            <a class="footer--button__prev" routerLink="/home" routerLinkActive="active">
                <i class="material-icons">chevron_left</i><span class="hide-small">Retour</span>
            </a>
            <a class="footer--button__next" (click)="onNextClick()">
                <span class="hide-small">Continuer</span><i class="material-icons">chevron_right</i>
            </a>
        </div>
    </section>
    <!-- Right part : Image (only on large devices) -->
    <section id="section--image--morphologie"></section>
    <!-- Modal -->
    <section id="section--modal" [ngStyle]="{'display': (selectedModal != null) ? 'flex' : 'none'}">
        <div class="modal--container">
            <div class="modal--menu">
                <i class="material-icons btnClose" (click)="closeModal()">close</i>
                <h3>Comment mesurer ?</h3>
                <div class="modal--context" *ngIf="selectedModal != null">
                    <img [srcset]="selectedModal.image" alt="Image d'exemple pour la modal">
                    <div class="modale--content">
                        <h4>{{selectedModal.title}}</h4>
                        <p>
                            <i class="material-icons">straighten</i>
                            <span class="text__underline">Matériel nécessaire :</span> {{selectedModal.materiel}}
                        </p>
                        <p [innerHtml]="selectedModal.description"></p>
                    </div>
                </div>
                <div class="block vtmn-flex vtmn-flex-row vtmn-flex-wrap"
                     style="display: flex; flex-direction: row; align-items: center">
                    <button class="vtmn-btn vtmn-btn_size--stretched" (click)="closeModal()">OK, j'ai compris</button>
                </div>
            </div>
        </div>
    </section>
</main>