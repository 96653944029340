import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {InputService} from "../services/input/input.service";
import {SizeType} from "../services/size/size-type.model";

declare const gtag: Function;

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor(
      private router: Router,
      private inputService: InputService
  ) { }

  type = this.inputService.type;
  data = this.inputService.data;

  ngOnInit(): void {
  }

  getBikeType(): string {
    switch(this.inputService.type) {
      case SizeType.roadPerformance:
      case SizeType.roadConfort:
        return "Vélo de route"
      case SizeType.loisir:
        return "Gravel / VTT / VTC"
    }
  }

  getBikeSetting(): string {
    switch(this.inputService.type) {
      case SizeType.roadPerformance:
        return "Performance"
      case SizeType.roadConfort:
        return "Confort"
      case SizeType.loisir:
        return "Loisir"
    }
  }

  onNextClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_continuer_4" 
    })

    this.router.navigate(['/resultat'])
  }
}
