<main>
    <!-- Left part : Forms and inputs -->
    <section id="section--form">
        <!-- Navigation menu -->
        <div id="navigation">
            <img src="assets/images/logo-decalthlon.svg" alt="Logo Decathlon" id="logo--navigation__width">
            <div id="navigation--content">
                <!-- 1 -->
                <!-- line-valid : Display validate check icon and blue background -->
                <div class="navigation--box line-valid">
                    <a routerLink="/profil" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">1</span>
                    </a>
                    <p class="navigation--box__text">Profil</p>
                </div>
                <!-- 2 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/morphologie" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">2</span>
                    </a>
                    <p class="navigation--box__text">Morpho<span class="display-none">logie</span></p>
                </div>
                <!-- 3 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/pratique" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">3</span>
                    </a>
                    <p class="navigation--box__text">Pratique</p>
                </div>
                <!-- 4 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">4</a>
                    <p class="navigation--box__text">Récap<span class="display-none">itulatif</span></p>
                </div>
                <!-- 5 -->
                <div class="navigation--box">
                    <a href="#" class="navigation--box__circle">5</a>
                    <p class="navigation--box__text">Résultat</p>
                </div>
            </div>
        </div>
        <div id="form">
            <h1>Récapitulatif</h1>
            <h2>On fait le point !</h2>
            <p class="form--desc">Vérifiez les informations indiquées dans les étapes précédentes pour générer ensuite
                vos réglages personnalisés.</p>
            <div class="resume--block">
                <div id="first--section">
                    <div class="resume--desc">
                        <p>Entrejambe</p>
                        <p>{{data.entrejambeInCm}} cm</p>
                    </div>
                    <hr>
                    <div class="resume--desc">
                        <p>Bras</p>
                        <p>{{data.brasInCm}} cm</p>
                    </div>
                    <hr>
                    <div class="resume--desc">
                        <p>Taille</p>
                        <p>{{data.tailleInCm}} cm</p>
                    </div>
                </div>
                <hr>
                <div id="second--section">
                    <div class="resume--desc">
                        <p>Torse</p>
                        <p>{{data.torseInCm}} cm</p>
                    </div>
                    <hr>
                    <div class="resume--desc">
                        <p>Type de vélo</p>
                        <p>{{getBikeType()}}</p>
                    </div>
                    <hr>
                    <div class="resume--desc">
                        <p>Type de réglage</p>
                        <p>{{getBikeSetting()}}</p>
                    </div>
                </div>
            </div>
            <div id="form--circles__1"></div>
            <div id="form--circles__2"></div>
            <div id="form--circles__3"></div>
            <div id="form--circles__4"></div>
        </div>
        <div id="footer--morphologie">
            <a class="footer--button__prev" routerLink="/pratique" routerLinkActive="active"><i class="material-icons">chevron_left</i><span class="hide-small">Retour</span></a>
            <a class="footer--button__next" (click)="onNextClick()">
                <span class="hide-small">Continuer</span><i class="material-icons">chevron_right</i>
            </a>
        </div>
    </section>
    <!-- Right part : Image (only on large devices) -->
    <section id="section--image--resume"></section>
    <!-- Modal -->
    <section id="section--modal">
        <div class="modal--container">
            <div class="modal--menu">
                <i class="fa-solid fa-xmark fa-xl"></i>
                <h3>Comment mesurer ?</h3>
                <div class="modal--context">
                    <img src="assets/images/image-modal.svg" alt="Image d'exemple pour la modal">
                    <div class="modale--content">
                        <h4>Entrejambe</h4>
                        <p><i class="fa-solid fa-ruler"></i><span class="text__underline">Matériel nécessaire :</span> un
                            mètre-ruban, une équerre
                            (ou objet
                            avec angle droit), un crayon et votre tenue de cycliste.</p>
                        <p>Mettez-vous debout, pieds nus et adossé au mur. Vos pieds sont légèrement écartes (environ 15 à
                            20 cm max.) et vos talons touchent le mur. <br>
                            Regardez droit devant vous, ensuite, prenez une équerre ou tout autre objet possédant un angle
                            droit (ex: un livre) contre le mur et faites le glisser entre vos jambes jusqu’à votre périné.
                            <br>
                            Mesurer la distance entre l’angle droit et le sol
                        </p>
                    </div>
                </div>
                <div class="block vtmn-flex vtmn-flex-row vtmn-flex-wrap"
                     style="display: flex; flex-direction: row; align-items: center">
                    <button class="vtmn-btn vtmn-btn_size--stretched">OK, j'ai compris</button>
                </div>
            </div>
        </div>
    </section>
</main>