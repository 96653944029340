import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {InputService} from "../services/input/input.service";
import {GenderType, SizeType} from "../services/size/size-type.model";

declare const gtag: Function;

@Component({
  selector: 'app-pratique',
  templateUrl: './pratique.component.html',
  styleUrls: ['./pratique.component.scss']
})
export class PratiqueComponent implements OnInit {
  constructor(
      private router: Router,
      private inputService: InputService,
  ) { }

  errorVisible = false
  typeSelected = null
  selectedApplication: SizeType = null

  typeAvailable = [
    {
      id: 1,
      text: "Route",
      application: [
        {
          id: "roadConfort",
          image: "assets/images/confort.png",
          title: "Confort",
          text: "Je recherche une position plus relevée et confortable."  
        },
        {
          id: "roadPerformance",
          title: "Performance",
          image: "assets/images/performance.png",
          text: "Je recherche une position plus engagée."
        },
      ]
    },
    {
      id: 2,
      text: "Gravel",
      application: [
        {
          id: "loisir",
          title: "Confort",
          image: "assets/images/confort.png",
          text: "Je recherche une position plus relevée et confortable."
        }
      ]
    },
    {
      id: 3,
      text: "VTT",
      application: [
        {
          id: "loisir",
          title: "Loisir",
          text: "Je recherche une position plus relevée et confortable.",
          image: "assets/images/confort.png"
        }
      ]
    },
    {
      id: 4,
      text: "VTC",
      application: [
        {
          id: "loisir",
          title: "Loisir",
          image: "assets/images/confort.png",
          text: "Je recherche une position plus relevée et confortable."
        }
      ]
    }
  ]

  ngOnInit(): void {
  }

  getClass(id: String): string {
    if((this.selectedApplication == SizeType.loisir && id == "loisir") || 
        (this.selectedApplication == SizeType.roadConfort && id == "roadConfort") ||
        (this.selectedApplication == SizeType.roadPerformance && id == "roadPerformance")) {
      return "vtmn-text-input--valid"
    }
    return ""
  }

  onApplicationSelected(value) {
    let sizeType: SizeType;
    switch(value.id) {
      case "loisir": 
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_position_confort" 
        })
        this.selectedApplication = SizeType.loisir; 
        break

      case "roadPerformance": 
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_position_performance" 
        })
        this.selectedApplication = SizeType.roadPerformance; 
        break

      case "roadConfort": 
        gtag('event','click', { 
          'event_category' : "Click", 
          'event_label' : "click_position_confort" 
        })
        this.selectedApplication = SizeType.roadConfort; 
        break
      default:
        this.errorVisible = true
        throw new Error("Invalid Type")
    }
  }

  onChange(value) {
    this.typeSelected = this.typeAvailable.find(e => e.id == value) 
    this.selectedApplication = null

    if(this.typeSelected != null) {
      switch(this.typeSelected.id) {
        case 1:
          gtag('event','click', { 
            'event_category' : "Click", 
            'event_label' : "click_type_de_velo_route" 
          })
          break
        case 2:
          gtag('event','click', { 
           'event_category' : "Click", 
           'event_label' : "click_type_de_velo_gravel" 
          })
          break
        case 3:
          gtag('event','click', { 
           'event_category' : "Click", 
           'event_label' : "click_type_de_velo_vtt" 
          })
          break
        case 4:
          gtag('event','click', { 
            'event_category' : "Click", 
            'event_label' : "click_type_de_velo_vtc" 
          })
          break
      }

      this.errorVisible = false
      
      if(this.typeSelected.application.length == 1) {
        this.onApplicationSelected(this.typeSelected.application[0])
      }
    }
  }

  onNextClick() {
    gtag('event','click', { 
      'event_category' : "Click", 
      'event_label' : "click_continuer_3" 
    })

    if(this.selectedApplication == null) {
      this.errorVisible = true
      throw new Error("Invalid type")
    }

    this.inputService.setType(this.selectedApplication)
    this.router.navigate(['/resume'])
  }
}
