<main>
    <!-- Left part : Forms and inputs -->
    <section id="section--form">
        <!-- Navigation menu -->
        <div id="navigation">
            <img src="assets/images/logo-decalthlon.svg" alt="Logo Decathlon" id="logo--navigation__width">
            <div id="navigation--content">
                <!-- 1 -->
                <!-- line-valid : Display validate check icon and blue background -->
                <div class="navigation--box line-valid">
                    <a routerLink="/profil" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">1</span>
                    </a>
                    <p class="navigation--box__text">Profil</p>
                </div>
                <!-- 2 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/morphologie" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">2</span>
                    </a>
                    <p class="navigation--box__text">Morpho<span class="display-none">logie</span></p>
                </div>
                <!-- 3 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">3</a>
                    <p class="navigation--box__text">Pratique</p>
                </div>
                <!-- 4 -->
                <div class="navigation--box line">
                    <a href="#" class="navigation--box__circle">4</a>
                    <p class="navigation--box__text">Récap<span class="display-none">itulatif</span></p>
                </div>
                <!-- 5 -->
                <div class="navigation--box">
                    <a href="#" class="navigation--box__circle">5</a>
                    <p class="navigation--box__text">Résultat</p>
                </div>
            </div>
        </div>
        <div id="form">
            <h1>Pratique</h1>
            <h2>Choisissez votre type de vélo</h2>
            <p class="form--desc">Choisissez le type de votre vélo ainsi que votre position la plus fréquente.</p>
            <div class="block velo--container">
                <div class="vtmn-select_container">
                    <label for="velo">Type de vélo</label>
                    <select name="velo" id="velo" (change)="onChange($event.target.value)">
                        <option value="" disabled="" selected="">Type</option>
                        <option *ngFor="let i of typeAvailable" [value]="i.id">{{i.text}}</option>
                    </select>
                    <p id="error-input-helper-1"
                       *ngIf="errorVisible"
                       class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
                       Merci de sélectionner un type d'utilisation
                    </p>
                </div>
            </div>
            <!-- Cards informations -->
            <div class="card--container" *ngIf="typeSelected != null" style="display: block;">
                <div class="card--desc">
                    <h4>Votre utilisation</h4>
                </div>
                <div class="card" *ngFor="let i of typeSelected.application" [ngClass]="getClass(i.id)"  (click)="onApplicationSelected(i)">
                    <img [srcset]="i.image" alt="Image de présentation" width="114" height="100"/>
                    <div>
                        <h4>{{i.title}}</h4>
                        <p>{{i.text}}</p>
                    </div>
                </div>
            </div>
            <div id="form--circles__1"></div>
            <div id="form--circles__2"></div>
            <div id="form--circles__3"></div>
            <div id="form--circles__4"></div>
        </div>
        <div id="footer--morphologie">
            <a class="footer--button__prev" routerLink="/morphologie" routerLinkActive="active"><i class="material-icons">chevron_left</i><span class="hide-small">Retour</span></a>
            <a class="footer--button__next" (click)="onNextClick()"><span class="hide-small">Continuer</span><i class="material-icons">chevron_right</i></a>
        </div>
    </section>
    <!-- Right part : Image (only on large devices) -->
    <section id="section--image--pratique"></section>
</main>