export enum GenderType {
    female, male
}
export enum SizeType {
    roadPerformance, roadConfort, loisir
}

export interface SizeParameter {
    gender?: GenderType,
    tailleInCm?: number,
    entrejambeInCm?: number,
    torseInCm?: number,
    brasInCm?: number
}