<main>
    <!-- Left part : Forms and inputs -->
    <section id="section--form">
        <!-- Navigation menu -->
        <div id="navigation-resultat">
            <img src="assets/images/logo-decalthlon.svg" alt="Logo Decathlon" id="logo--navigation__width">
            <div id="navigation--content">
                <!-- 1 -->
                <!-- line-valid : Display validate check icon and blue background -->
                <div class="navigation--box line-valid">
                    <a routerLink="/profil" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">1</span>
                    </a>
                    <p class="navigation--box__text">Profil</p>
                </div>
                <!-- 2 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/morphologie" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">2</span>
                    </a>
                    <p class="navigation--box__text">Morpho<span class="display-none">logie</span></p>
                </div>
                <!-- 3 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/pratique" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">3</span>
                    </a>
                    <p class="navigation--box__text">Pratique</p>
                </div>
                <!-- 4 -->
                <div class="navigation--box line-valid">
                    <a routerLink="/resume" routerLinkActive="active" class="navigation--box-v__circle">
                        <i class="material-icons">done</i>
                        <span class="text__hide">4</span>
                    </a>
                    <p class="navigation--box__text">Récap<span class="display-none">itulatif</span></p>
                </div>
                <!-- 5 -->
                <div class="navigation--box">
                    <a href="#" class="navigation--box__circle">5</a>
                    <p class="navigation--box__text">Résultat</p>
                </div>
            </div>
        </div>
        <div id="form" style="text-align: center; height: 55vh; padding: 1rem 1.8rem;">
            <h1>Résultat</h1>
            <h2>Retrouvez ci-dessous votre configuration 100% personnalisée !</h2>
            <div class="resultat--block">
                <!-- Card : Hauteur de selle -->
                <div class="block">
                    <div class="vtmn-card">
                        <div class="vtmn-card_image">
                            <img src="assets/images/hauteur-selle.svg" alt="Hauteur de selle">
                        </div>
                        <div class="vtmn-card_content">
                            <div class="card--content_flex">
                                <h3 class="vtmn-card_content--title">Selle - Hauteur</h3>
                                <p>{{hauteurDeSelle}} cm</p>
                            </div>
                            <a (click)="openModal(resultatType.HauteurDeSelle)">
                                <i class="material-symbols-outlined">info</i>
                                <p>En savoir plus</p>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Card : Recul de selle -->
                <div class="block">
                    <div class="vtmn-card">
                        <div class="vtmn-card_image">
                            <img src="assets/images/recul-selle.svg" alt="Recul de selle">
                        </div>
                        <div class="vtmn-card_content">
                            <div class="card--content_flex">
                                <h2 class="vtmn-card_content--title">Selle - Recul</h2>
                                <p>{{reculDeSelle}} cm</p>
                            </div>
                            <a (click)="openModal(resultatType.ReculDeSelle)">
                                <i class="material-symbols-outlined">info</i>
                                <p>En savoir plus</p>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Card : Drop -->
                <div class="block">
                    <div class="vtmn-card">
                        <div class="vtmn-card_image">
                            <img src="assets/images/drop.svg" alt="Drop">
                        </div>
                        <div class="vtmn-card_content">
                            <div class="card--content_flex">
                                <h2 class="vtmn-card_content--title">Drop</h2>
                                <p>{{hauteurSelleCadre}} cm</p>
                            </div>
                            <a (click)="openModal(resultatType.HauteurSelleCadre)">
                                <i class="material-symbols-outlined">info</i>
                                <p>En savoir plus</p>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Card : Selle et Guidon -->
                <div class="block">
                    <div class="vtmn-card">
                        <div class="vtmn-card_image">
                            <img src="assets/images/selle-guidon.svg" alt="Selle / Guidon">
                        </div>
                        <div class="vtmn-card_content">
                            <div class="card--content_flex">
                                <h2 class="vtmn-card_content--title">Selle - Guidon</h2>
                                <p>{{selleGuidon}} cm</p>
                            </div>
                            <a (click)="openModal(resultatType.SelleGuidon)">
                                <i class="material-symbols-outlined">info</i>
                                <p>En savoir plus</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="footer--resultat">
            <a (click)="onCTAClick()" href="https://www.decathlon.fr/browse/c0-tous-les-sports/c1-velo-cyclisme/c4-selles-velo/_/N-1owz0se" target="_blank" class="footer--button__how">Découvrez nos Selles Vélos</a>
            <a (click)="onBackClick()" class="vtmn-link">Revenir en arrière</a>
        </div>
    </section>
    <!-- Right part : Image (only on large devices) -->
    <section id="section--image--resultat"></section>
    <!-- Modal -->
    <section id="pratique--modal" [ngStyle]="{'display': (selectedModal != null) ? 'flex' : 'none'}">
        <div class="modal--container">
            <div class="modal--menu">
                <i class="material-icons btnClose" (click)="closeModal()">close</i>
                <h3>Choix de votre utilisation</h3>
                <div class="modal--context">
                    <div *ngFor="let i of selectedModal">
                        <div id="first-content">
                            <img [srcset]="i.image" alt="Image d'exemple pour la modal">
                            <div class="modale--content">
                                <h4>{{i.title}}</h4>
                                <p>
                                    <i class="material-icons">straighten</i>
                                    <span class="text__underline">Matériel nécessaire :</span> {{i.materiel}}
                                </p>
                                <p [innerHtml]="i.description"></p>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="block vtmn-flex vtmn-flex-row vtmn-flex-wrap"
                     style="display: flex; flex-direction: row; align-items: center">
                    <button class="vtmn-btn vtmn-btn_size--stretched" (click)="closeModal()">OK, j'ai compris</button>
                </div>
            </div>
        </div>
    </section>
</main>